export const environment = {
    production: false,
    apiUrl: 'https://mdcompapi01-tst.azurewebsites.net',
    webUrl: 'https://mdcomp01-tst.azurewebsites.net',
    msalConfig: {
        auth: {
            clientId: '44e062b7-912e-405f-a9ec-baf6ae1beed4',
            authority: 'https://login.microsoft.com/16532572-d567-4d67-8727-f12f7bb6aed3'
        }
    },
    apiConfig: {
        scopes: ['api://f34e1889-60a7-4b38-8eb2-bb3e156c07cd/user_impersonation'],
        uri: '/',
    },
};
